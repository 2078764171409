<template>
  <div class="tenant-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
let validName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //姓名支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("姓名格式不对"));
      } else {
        callback();
      }
    }
  };
  let validMobile = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("电话号码格式不对"));
      } else {
        callback();
      }
    }
  };
  let validIdNumber = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //证件号码仅支持数字，大小写字母，1~18位
      let reg = /^[A-z0-9]{1,18}$/;
      if (!reg.test(value)) {
        callback(new Error("证件号码格式不对"));
      } else {
        callback();
      }
    }
  };
  let validCity = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //居住城市仅汉字，1~24个字符
      let reg = /^[\u4E00-\u9FA5]{1,24}$/;
      if (!reg.test(value)) {
        callback(new Error("居住城市格式不对"));
      } else {
        callback();
      }
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        rentalStyle: "0", // 0长租 1短租
        opt: {
          title: "房客列表",
          search: [{
              key: "realName",
              label: "姓名",
              maxlength: 16,
              rules: [{
                validator: validName,
                trigger: ['blur']
              }]
            },
            {
              key: "phoneNum",
              label: "手机号码",
              maxlength: 11,
              rules: [{
                validator: validMobile,
                trigger: ['blur']
              }]
            },
            {
              key: "idType",
              label: "证件类型",
              type: "select-no-filterable",
              opt: {
                list: [{
                    value: "",
                    label: "全部"
                  },
                  ..._this.certTypeOptions
                ]

              }
            },
            {
              key: "idCardNo",
              label: "证件号码",
              maxlength: 18,
              rules: [{
                validator: validIdNumber,
                trigger: ['blur']
              }]
            },
            {
              key: "city",
              label: "居住城市",
              maxlength: 24,
              rules: [{
                validator: validCity,
                trigger: ['blur']
              }]
            },
            {
              key: "userType",
              label: "用户类型",
              type: "select-no-filterable",
              opt: {
                list: _this.tenantUserOption
              }
            },
            {
              key: "resourceType",
              label: "用户来源",
              type: "select-no-filterable",
              opt: {
                list: _this.userFromOption
              }
            },
            {
              key: "date",
              label: "注册时间",
              type: "data-range"
            },
          ],
          buttons: [],
          columns: [{
              label: "姓名",
              key: "realName",
              width: 120,
              on: row => {
                _this.$router.push(
                  "/main/tenant/detailTab/show/" + row.userId
                );
              }
            },
            {
              label: "性别",
              key: "sexStr",
              width: 120
            },
            {
              label: "居住城市",
              key: "city"
            },
            {
              label: "手机号码",
              key: "phoneNum"
            },
            {
              label: "证件类型",
              key: "cardTypeStr"
            },
            {
              label: "证件号码",
              key: "idCardNoStr"
            },
            {
              label: "用户来源",
              key: "resourceTypeStr"
            },
            {
              label: "用户类型",
              key: "userTypeStr"
            },
            {
              label: "注册时间",
              key: "createdDate"
            },
            {
              label: "账号状态",
              key: "cancelStateStr"
            },
          ]
        }
      };
    },
    created() {
      console.log("tenant-list created!!");

    },
    activated() {
      // this.getApartmentList();
      this.getMenuName(this.$route.path)
    },
    methods: {
      onGet(opt) {
        console.log(opt.searchForm);
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          startTime: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : null,
          endTime: opt.searchForm['date'] ? moment(opt.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") : null,
          ...opt.searchForm,
        };
        let url = "user-service/v1/user/tenant/page";
        this.post(url, dto, {
          isUseResponse: true
        }).then(res => {
          if (res.data.data.data) {
            for (let i = 0; i < res.data.data.data.length; i++) {
              let item = res.data.data.data[i];
              item.cancelStateStr = ["正常","注销中","已注销"][item.cancelState];
              item.resourceTypeStr = item.resourceType!=null ? ['PMS排房','云数排房','添加同住人','授权开门',
              '分享钥匙','App注册','小程序注册'][item.resourceType -1] : '小程序注册'; //老数据默认是小程序注册
              item.userTypeStr = item.authState!=null ? ['实名用户', '实名用户', '实名用户','普通用户'][item.authState] : '普通用户';
              // item.cardTypeStr = (item.idType && item.idType > 0) ? this.certTypeArr[item.idType] : '--';
              item.realName = item.realName ? item.realName : '--';
              item.idCardNoStr = item.idCardNo ? item.idCardNo : '--';
              item.city = item.city ? item.city : '--';
              item.phoneNum = item.phoneNum ? item.phoneNum : '--';
              item.createdDate = item.createdDate ? item.createdDate : '--';
              //1-男 2-女 其他值都显示--
              if(item.gender == 1) {
                item.sexStr = '男';
              }else if(item.gender == 2) {
                item.sexStr = '女';
              }else {
                item.sexStr = '--';
              }
              if(item.idType && ['1','2','3','4','5','6','7'].indexOf(item.idType+'')!=-1){
                  item.cardTypeStr=this.certTypeArr[item.idType]
              }
              else{
                item.cardTypeStr=item.cardStrType?this.certTypeArr[this.cardStrToNum(item.cardStrType)]:'--';
              }
            }
          } else {
            res.data.data.data = [];
            res.data.data.total = 0;
          }
          opt.cb(res.data.data);
        });
      },

      // 合同id获取房间id
      getTalentInfo(id) {
        let dto = {};
        if (this.rentalStyle == "0") {
          dto.contractId = id;
        } else {
          dto.id = id;
        }
        let url =
          this.rentalStyle == "0" ?
          "tenant-service/leasecontract/queryTalentInfo" :
          "landlord-service/shortRent/getShortRent";
        return this.post(url, dto).then(data => {
          return data.roomId || "";
        });
      },

      //获取房源列表
      getApartmentList() {
        var dto = {
          pageNumber: "1",
          pageSize: "99999",
          landlordId: this.cache.getLS("userInfo")["roleType"] == "1" ?
            null : this.cache.getLS("userInfo")["userId"]
        };
        this.post("landlord-service/apartment/queryPagesApartment", dto).then(
          res => {
            let returnData = {};
            res.forEach(data => {
              returnData[data.id] = data.name;
            });
            res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
            res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
            this.opt.search[2]["opt"]["list"] = res;
          }
        );
      }
    }
  };
</script>